import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import {
  HandShaperDataTableRow,
  HandShaperSessionData,
} from "types/games/handShaperGameTypes";
import GameDetailLayout from "../GameDetailLayout";

function HandShaperDetail() {
  const { t } = useTranslation();
  const { results } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "points", label: t("game.totalPoints") },
    { key: "incorrects", label: t("game.numberOfIncorrections") },
    { key: "totalHand", label: t("game.totalHand") },
    { key: "gameLevel", label: t("game.calculationDifficulty") },
  ];

  const convertDataToDataTable = (
    _data: HandShaperSessionData[]
  ): HandShaperDataTableRow[] | null => {
    if (_data.length === 0) return null;

    return _data.map((session: HandShaperSessionData, index: number) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        points: session.points,
        incorrects: session.incorrects,
        totalHand: session.totalHand,
        gameLevel: session.gameLevel,
      };
    });
  };

  const totalSpentTime = results.totalTime.toFixed(0);

  return (
    <GameDetailLayout
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      data={results.result}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default HandShaperDetail;
