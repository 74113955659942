import { getMe } from "appdata/myself/myselfSlice";
import MasterLayout from "layouts/MasterLayout";
import CreateDashboard from "pages/CreateDashboard/CreateDashboard";
import Dashboard from "pages/Dashboard/Dashboard";
import Login from "pages/Login/Login";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppDispatch } from "appdata/store"; // Bạn cần import kiểu AppDispatch nếu bạn đã cấu hình
import Chart from "pages/Chart/Chart";

// Hàm kiểm tra nếu user đã đăng nhập
const isAuthenticated = (): boolean => {
  return !!localStorage.getItem("mi01_auth"); // Trả về true nếu có mi01_auth
};

// Kiểu cho props của ProtectedRoute
interface ProtectedRouteProps {
  children: ReactNode;
}

// ProtectedRoute component để kiểm tra quyền truy cập
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  if (!isAuthenticated()) {
    // Chuyển hướng đến login nếu không có mi01_auth
    return <Navigate to="/login" />;
  }

  // Hiển thị nội dung nếu đã đăng nhập
  return <>{children}</>;
};

function AppRoutes() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>(); // Đảm bảo useDispatch có kiểu chính xác

  // Đoạn này để thay đổi ngôn ngữ và dispatch action getMe()
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("mi01_la") ?? "ja");
    if (isAuthenticated()) {
      dispatch(getMe());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <MasterLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/chart" element={<Chart />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route
          path="/dashboard/create-dashboard"
          element={<CreateDashboard />}
        ></Route>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
