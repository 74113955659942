import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PatientsOfDoctor from "components/PatientsOfDoctor/PatientsOfDoctor";

import NoDataImg from "assets/img/no-data.webp";
import PlusIcon from "assets/icons/plus.svg";

import styles from "./Dashboard.module.scss";
import { PatientProvider } from "contexts/patient-context/PatientContext";
import {
  getPatients,
  getPatientsOfDoctor,
} from "appdata/patients/patientsSlice";
import { useEffect } from "react";
import { AppDispatch, RootState } from "appdata/store";
import { setLoading } from "appdata/loader/loaderSlice";

function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const patientsRedux = useSelector((state: RootState) => state.patientsRedux);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getPatients());
    dispatch(getPatientsOfDoctor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patientsRedux.patients && patientsRedux.patientsOfDoctor) {
      dispatch(setLoading(false));
    }
  }, [patientsRedux]);

  return (
    <>
      <div className={styles.dashboard_container}>
        {patientsRedux.patientsOfDoctor &&
          patientsRedux.patientsOfDoctor.length > 0 && (
            <div className={styles.management_patient_dashboard}>
              <div className={styles.management_patient_dashboard_header}>
                <div className={styles.title}>
                  {`${t("dashboard_page.totalPatients")} (${
                    patientsRedux.patientsOfDoctor.length ?? 0
                  })`}
                </div>
              </div>
              <PatientProvider>
                <PatientsOfDoctor />
              </PatientProvider>
            </div>
          )}

        {patientsRedux.patientsOfDoctor?.length === 0 && (
          <>
            {console.log("No data available for patientsOfDoctor")}
            <div className={styles.table_nodata}>
              <img src={NoDataImg} alt="nodata"></img>
              <span>{t("table.noData")}</span>
              <span>{t("table.noDataYet")}</span>
              <button
                className={`${styles.create_dashboard_btn} button_primary rounded`}
                onClick={() => navigate("/dashboard/create-dashboard")}
              >
                <img alt="plus" src={PlusIcon}></img>
                {t(`button.createDashboard`)}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
