import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

// Đăng ký các thành phần của Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ApiData {
  labels: string[];
  datasets: {
    label: string;
    data: (number | null)[];
    borderColor: string;
    backgroundColor: string;
  }[];
}

const Chart: React.FC = () => {
  // Dữ liệu từ API
  const apiData: ApiData = {
    labels: [
      "2024/10/10 03:18",
      "2024/10/10 03:18",
      "2024/10/10 03:19",
      "2024/10/10 03:25",
      "2024/10/11 07:21",
      "2024/10/11 07:21",
      "2024/10/11 09:04",
      "2024/10/11 09:08",
      "2024/10/21 02:48",
    ],
    datasets: [
      {
        label: "HeadUp",
        data: [null, null, null, null, null, null, null, null, null],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
      },
      {
        label: "HeadDown",
        data: [
          46.685433399999994, 23.8284874, 25.6323128, 45.276055, 43.74452015,
          21.900246600000003, 37.43979335, 38.5838563, 35,
        ],
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  // Cấu hình cho biểu đồ
  const options: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Line Chart Example",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Time (Date/Time)",
          color: "#333", // Màu của tên trục X
          font: {
            weight: "bold",
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Head Movement (Degrees)",
          color: "#333", // Màu của tên trục Y
          font: {
            weight: "bold",
            size: 14,
          },
        },
      },
    },
  };

  return <Line data={apiData} options={options} />;
};

export default Chart;
