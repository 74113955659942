import { getPdfOfPatient } from "appdata/patients/patientsSlice";
import { AppDispatch } from "appdata/store";
import CalendarIcon from "assets/icons/calendar.svg";
import ChartIcon from "assets/icons/chart.svg";
import PdfIcon from "assets/icons/pdf.svg";
import ClockImg from "assets/img/clock.webp";
import NoDataImg from "assets/img/no-data.webp";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Popover from "components/Popover/Popover";
import TableSticky, { StyledItem } from "components/TableSticky/TableSticky";
import { usePatient } from "contexts/patient-context/PatientContext";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { DateRange, DayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { formatTimeV1 } from "utils/time";
import styles from "./index.module.scss";

interface GameDetailLayoutProps {
  totalSpentTime: number;
  headTabs: Array<{ key: string; label: string }>;
  columnStyles?: StyledItem[];
  data: any[];
  dataConverter: (data: any[]) => any[] | null;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const today = new Date();

function GameDetailLayout({
  totalSpentTime,
  headTabs,
  columnStyles,
  data,
  dataConverter,
}: GameDetailLayoutProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    patientId,
    selectedGameId,
    analyticsGameOfPatient,
    selectedRange,
    setSelectedRange,
    options,
  } = usePatient();

  const convertedData = dataConverter(data);
  const [isOverviewVisible, setIsOverviewVisible] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isChartPopupOpen, setIsChartPopupOpen] = useState(false);
  const [anchorElCalendar, setAnchorElCalendar] = useState<HTMLElement | null>(
    null
  );

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const calendarOpen = Boolean(anchorElCalendar);
  const idCalendar = calendarOpen ? "region-popover" : undefined;

  const handleCalendarClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElCalendar(e.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchorElCalendar(null);
  };

  const handleRangeChange = (range: DateRange | undefined) => {
    if (range && range.from && range.to) {
      // Kiểm tra xem 'from' và 'to' có cùng ngày hay không
      const fromDate = new Date(range.from);
      const toDate = new Date(range.to);

      if (
        fromDate.getFullYear() === toDate.getFullYear() &&
        fromDate.getMonth() === toDate.getMonth() &&
        fromDate.getDate() === toDate.getDate()
      ) {
        // Nếu cùng ngày, thiết lập from là 00:00:00 và to là 23:59:59
        fromDate.setHours(0, 0, 0, 0); // 0h
        toDate.setHours(23, 59, 59, 999); // 23h59
      }

      // Cập nhật selectedRange với giá trị đã điều chỉnh
      setSelectedRange({ from: fromDate, to: toDate });
    } else {
      // Nếu range không có giá trị, thiết lập selectedRange thành undefined hoặc giá trị mặc định
      setSelectedRange(undefined);
    }
  };

  const formatDisplayDate = (date: Date | undefined) => {
    return date
      ? date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })
      : "";
  };

  useEffect(() => {
    if (!selectedRange) return;

    const start = selectedRange?.from
      ? selectedRange.from.toISOString()
      : new Date(today.setHours(0, 0, 0, 0)).toISOString();

    const end = selectedRange?.to
      ? selectedRange.to.toISOString()
      : new Date(today.setHours(23, 59, 59, 999)).toISOString();

    dispatch(
      getPdfOfPatient({
        patientId: patientId!,
        gameId: selectedGameId!,
        start: start,
        end: end,
      })
    )
      .unwrap()
      .then((response) => {
        const blob = new Blob([response.blob], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRange]);

  useEffect(() => {
    // Kích hoạt hiệu ứng cho phần overview và detail
    setTimeout(() => setIsOverviewVisible(true), 300); // Delay cho game_detail sau 300ms
    setTimeout(() => setIsDetailVisible(true), 300); // Delay cho game_detail sau 300ms
  }, []);

  return (
    <div className={styles.right_content}>
      <div className={styles.tools_box}>
        <button
          onClick={handleCalendarClick}
          className={`${styles.calendar_btn} rounded`}
        >
          <span>
            {selectedRange && selectedRange.from && selectedRange.to
              ? `${formatDisplayDate(selectedRange.from)} - ${formatDisplayDate(
                  selectedRange.to
                )}`
              : "Select Date Range"}
          </span>
          <img
            src={CalendarIcon}
            alt="Calendar icon"
            style={{ objectFit: "cover" }}
          />
        </button>

        <Popover
          id={idCalendar}
          open={calendarOpen}
          anchorEl={anchorElCalendar}
          onClose={handleCloseCalendar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={styles.date_picker_overlay}>
            <DayPicker
              mode="range"
              selected={selectedRange}
              onSelect={handleRangeChange}
            />
          </div>
        </Popover>

        <div
          className={styles.vertical_line}
          style={{ visibility: analyticsGameOfPatient ? "visible" : "hidden" }}
        ></div>

        <button
          className={`${styles.pdf_btn} rounded`}
          onClick={() => setIsPreviewOpen(true)}
          style={{ visibility: analyticsGameOfPatient ? "visible" : "hidden" }}
        >
          <span>{`${t("dashboard_page.exportPdf")}`}</span>
          <img
            style={{
              objectFit: "cover",
            }}
            src={PdfIcon}
            alt="PDF icon"
          />
        </button>

        <div
          className={styles.vertical_line}
          style={{ visibility: analyticsGameOfPatient ? "visible" : "hidden" }}
        ></div>

        <button
          className={`${styles.pdf_btn} rounded`}
          onClick={() => {
            setIsChartPopupOpen(true);
          }}
          style={{ visibility: analyticsGameOfPatient ? "visible" : "hidden" }}
        >
          <span>{`${t("dashboard_page.chart")}`}</span>
          <img
            style={{
              objectFit: "cover",
            }}
            src={ChartIcon}
            alt="Chart icon"
          />
        </button>

        {isPreviewOpen && pdfUrl && (
          <Popup
            contentStyle={{ zIndex: 1001 }}
            open={isPreviewOpen}
            modal
            onClose={() => setIsPreviewOpen(false)}
            closeOnDocumentClick
          >
            <div
              style={{
                width: "80vw",
                height: "100vh",
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src={pdfUrl}
                title="PDF Preview"
                style={{ border: "none" }}
              />
            </div>
          </Popup>
        )}

        <Popup
          contentStyle={{ zIndex: 1001 }}
          open={isChartPopupOpen}
          modal
          onClose={() => setIsChartPopupOpen(false)}
          closeOnDocumentClick
        >
          <div className={styles.customPopup}>
            <button
              className={`${styles.close_btn} size_1`}
              onClick={() => setIsChartPopupOpen(false)}
            >
              &times;
            </button>
            {/* <div className={`size_1 ${styles.title}`}>
              {t("common_text.userSwitching")}
            </div> */}

            <div className={styles.popup_content}>
              <Line data={analyticsGameOfPatient} options={options} />
            </div>
          </div>
        </Popup>
      </div>

      {data.length > 0 && (
        <>
          <div
            className={`${styles.game_overview} ${
              isOverviewVisible ? styles.visible : ""
            }`}
          >
            <div className={styles.totalSpentTime}>
              <img alt="clock" src={ClockImg}></img>
              <div className={styles.time}>
                <span>{t("table.totalTime")}</span>
                <span>{formatTimeV1(totalSpentTime)}</span>
              </div>
            </div>
          </div>
          <div
            className={`${styles.game_detail} ${
              isDetailVisible ? styles.visible : ""
            }`}
          >
            <div className={styles.game_detail_title}>{`${t(
              "game.gameDetail"
            )}`}</div>
            <TableSticky
              headTabs={headTabs}
              data={convertedData}
              columnStyles={columnStyles}
            />
          </div>
        </>
      )}

      {data.length === 0 && (
        <div className={styles.table_nodata}>
          <div>
            <img src={NoDataImg} alt="nodata"></img>
            <div>{t("table.noData")}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GameDetailLayout;
