import TableSticky from "components/TableSticky/TableSticky";
import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";
import { LookDataOfNeckThrowSession } from "types/games/neckThrowGameTypes";

function NeckThrowSessionDetail() {
  const { t } = useTranslation();
  const { sessionDetailSelected } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "type", label: t("game.type") },
    { key: "maxAngle", label: t("game.maxAngle") },
    { key: "holdTime", label: t("game.holdTime") },
  ];

  const convertDataToDataTable = (data: LookDataOfNeckThrowSession[]) => {
    if (!data || data.length === 0) return []; // Trả về mảng rỗng thay vì null
    return data.map((el, index) => {
      return {
        order: index + 1,
        type: el.name === "HeadUp" ? t("game.headUp") : t("game.headDown"),
        maxAngle: el.maxAngle.toFixed(2),
        holdTime: el.holdTime.toFixed(2),
      };
    });
  };

  return (
    <TableSticky
      headTabs={headTabs}
      data={convertDataToDataTable(sessionDetailSelected.lookDatas)}
    />
  );
}

export default NeckThrowSessionDetail;
