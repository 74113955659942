import { ConfigProvider, Pagination } from "antd";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PaginationMetadata } from "types/apiTypes";

interface PaginationCusProps {
  metadataPage: PaginationMetadata | null;
  onClick: (pageParam: { page: number; pageSize: number }) => void;
  styles?: React.CSSProperties;
  align?: "center" | "start" | "end";
}

const PaginationCus: React.FC<PaginationCusProps> = ({
  metadataPage,
  onClick = () => {},
  styles,
  align,
}) => {
  const [paginationSize, setPaginationSize] = useState({
    fontSize: 14,
    itemSize: 32,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width >= 3600) {
        setPaginationSize({ fontSize: 28, itemSize: 70 });
      } else if (width >= 2400) {
        setPaginationSize({ fontSize: 18, itemSize: 40 });
      } else {
        setPaginationSize({ fontSize: 14, itemSize: 32 });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!metadataPage) {
    return null; // Trả về null nếu không có dữ liệu
  }

  const { currentPage, total, limit } = metadataPage;

  const handleClickPageButton = (page: number) => {
    const pageParam = { page: page, pageSize: limit };
    onClick(pageParam);
  };

  return (
    <div style={{ ...styles }}>
      <ConfigProvider
        theme={{
          token: {
            fontSize: paginationSize.fontSize,
          },
          components: {
            Pagination: {
              itemSize: paginationSize.itemSize,
            },
          },
        }}
      >
        <Pagination
          pageSize={limit}
          current={currentPage}
          defaultCurrent={1}
          total={total}
          onChange={handleClickPageButton}
          align={align ?? "end"}
          hideOnSinglePage
        />
      </ConfigProvider>
    </div>
  );
};

PaginationCus.propTypes = {
  metadataPage: PropTypes.shape({
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.object,
};
export default PaginationCus;
