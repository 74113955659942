import axios, { AxiosError } from "axios";

// Tạo instance của Axios
const axiosInstance = axios.create({
  baseURL: `${window._env_.REACT_APP_API_URL}/api/v1`,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Flag to check if the refresh token request is ongoing
let isRefreshing = false;
// Queue to store pending requests
let failedQueue: {
  resolve: (value?: string) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (
  error: AxiosError | null,
  token: string | undefined = undefined
) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const setupAxios = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      // Lấy token từ localStorage
      const mi01Auth = localStorage.getItem("mi01_auth");

      // Nếu có token, thêm accessToken vào header Authorization
      if (mi01Auth) {
        const { accessToken } = JSON.parse(mi01Auth); // Parse and get accessToken
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      return config;
    },
    (error) => {
      // Xử lý lỗi trong request
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      // Return response if successful
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (isRefreshing) {
          // Push the request to the queue and wait for the refresh to complete
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }
        console.log(error);

        isRefreshing = true;
        const mi01Auth = localStorage.getItem("mi01_auth");
        if (!mi01Auth) {
          // Chuyển hướng nếu không có refreshToken
          window.location.href = "/login";
          return Promise.reject(new Error("No auth token available"));
        }
        if (mi01Auth) {
          const { refreshToken } = JSON.parse(mi01Auth);

          if (!refreshToken) {
            // Chuyển hướng nếu không có refreshToken
            window.location.href = "/login";
            return Promise.reject(new Error("No refresh token available"));
          }

          try {
            const response = await axios.post(
              `${window._env_.REACT_APP_API_URL}/api/v1/auth/refresh`,
              { refreshToken },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            const newAuthData = {
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
            };
            localStorage.setItem("mi01_auth", JSON.stringify(newAuthData));

            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.accessToken}`;
            processQueue(null, response.data.accessToken);

            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${response.data.accessToken}`;
            return axiosInstance(originalRequest);
          } catch (refreshError) {
            processQueue(error as AxiosError, undefined); // Ép kiểu 'error' thành AxiosError
            localStorage.removeItem("mi01_auth");
            window.location.href = "/login";
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        }
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
