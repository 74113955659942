import { getGames } from "appdata/games/gamesSlice";
import { setLoading } from "appdata/loader/loaderSlice";
import {
  getAnalyticsGameOfPatient,
  getResultGameOfPatient,
} from "appdata/patients/patientsSlice";
import { AppDispatch } from "appdata/store";
import { ChartOptions } from "chart.js";
import { GameId } from "constants/gameIds";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DateRange } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GET_CHART_CONFIG_BY_GAME_ID } from "types/charts/chartConfig";
import { LineChartData } from "types/charts/lineChartTypes";
import { toastConfig } from "utils/toastConfig";

interface PatientContextType {
  patientId: string | null;
  setPatientId: React.Dispatch<React.SetStateAction<string | null>>;
  results: any;
  setResults: React.Dispatch<React.SetStateAction<any>>;
  selectedGameId: GameId | null;
  setSelectedGameId: React.Dispatch<React.SetStateAction<GameId | null>>;
  sessionDetailSelected: any;
  setSessionDetailSelected: React.Dispatch<React.SetStateAction<any>>;
  analyticsGameOfPatient: any;
  setAnalyticsGameOfPatient: React.Dispatch<React.SetStateAction<any>>;
  selectedRange: DateRange | undefined;
  setSelectedRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  options: ChartOptions<"line">;
  setOptions: React.Dispatch<React.SetStateAction<ChartOptions<"line">>>;
}

const defaultOptions: ChartOptions<"line"> = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Line Chart",
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Time (Date/Time)",
        color: "#333", // Màu của tên trục X
        font: {
          weight: "bold",
          size: 14,
        },
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Head Movement (Degrees)",
        color: "#333", // Màu của tên trục Y
        font: {
          weight: "bold",
          size: 14,
        },
      },
    },
  },
};

const PatientContext = createContext<PatientContextType | undefined>(undefined);
const today = new Date();

export const PatientProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const gamesRedux = useSelector((state: any) => state.gamesRedux);

  const [patientId, setPatientId] = useState<string | null>(null);
  const [results, setResults] = useState<any | null>(null);
  const [selectedGameId, setSelectedGameId] = useState<GameId | null>(null);
  const [sessionDetailSelected, setSessionDetailSelected] = useState<
    any | null
  >(null);
  const [analyticsGameOfPatient, setAnalyticsGameOfPatient] = useState<
    any | null
  >(null);
  const [options, setOptions] = useState<ChartOptions<"line">>(defaultOptions);
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>({
    from: new Date(today.setHours(0, 0, 0, 0)),
    to: new Date(today.setHours(23, 59, 59, 999)),
  });

  useEffect(() => {
    if (!patientId || !gamesRedux.games || !selectedGameId) return;

    const start = selectedRange?.from
      ? selectedRange.from.toISOString()
      : new Date(today.setHours(0, 0, 0, 0)).toISOString();

    const end = selectedRange?.to
      ? selectedRange.to.toISOString()
      : new Date(today.setHours(23, 59, 59, 999)).toISOString();

    dispatch(
      getResultGameOfPatient({
        patientId: patientId,
        gameId: selectedGameId,
        start,
        end,
      })
    )
      .unwrap()
      .then((response) => {
        setResults({ ...response.data, gameId: selectedGameId });
        setSelectedGameId(selectedGameId);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        toast.error("Error !", toastConfig);
      });

    dispatch(
      getAnalyticsGameOfPatient({
        patientId: patientId,
        gameId: selectedGameId,
        start,
        end,
      })
    )
      .unwrap()
      .then((response) => {
        const { labels, datasets } = response.data;
        if (!selectedGameId) return;

        // Nếu lỗi ở đây thì chứng tỏ backend chưa support chart của game đó
        const chartConfig = GET_CHART_CONFIG_BY_GAME_ID[selectedGameId](
          labels,
          datasets
        );

        setOptions(chartConfig.options);
        setAnalyticsGameOfPatient(chartConfig.data as LineChartData);
      })
      .catch((error) => {
        setAnalyticsGameOfPatient(null);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, gamesRedux, selectedGameId, selectedRange]);

  useEffect(() => {
    dispatch(getGames())
      .unwrap()
      .then((response) => {
        setSelectedGameId(response.data[0].id);
      })
      .catch((error) => {
        toast.error("Error !", toastConfig);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(
    () => ({
      patientId,
      setPatientId,
      results,
      setResults,
      selectedGameId,
      setSelectedGameId,
      sessionDetailSelected,
      setSessionDetailSelected,
      analyticsGameOfPatient,
      setAnalyticsGameOfPatient,
      selectedRange,
      setSelectedRange,
      options,
      setOptions,
    }),
    [
      patientId,
      results,
      selectedGameId,
      sessionDetailSelected,
      analyticsGameOfPatient,
      selectedRange,
      options,
    ]
  );

  return (
    <PatientContext.Provider value={contextValue}>
      {children}
    </PatientContext.Provider>
  );
};

// Custom hook to use the PatientContext
export const usePatient = () => {
  const context = useContext(PatientContext);
  if (context === undefined) {
    throw new Error("usePatient must be used within a PatientProvider");
  }
  return context;
};
