import PropTypes from "prop-types";
import styles from "./SearchBar.module.scss";
import { useTranslation } from "react-i18next";

interface SearchBarProps {
  valueSearch: string;
  setValueSearch: (value: string) => void;
  containerStyle?: React.CSSProperties; // Use React.CSSProperties for inline styles
}

const SearchBar: React.FC<SearchBarProps> = ({
  valueSearch,
  setValueSearch,
  containerStyle,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.search_bar} style={containerStyle}>
      <div className={styles.search_icon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1vw"
          height="1vw"
          fill="currentColor"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001a1 1 0 0 0 .152 1.318l3.85 3.85a1 1 0 0 0 1.415-1.415l-3.85-3.85a1 1 0 0 0-1.318-.152zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
      </div>
      <input
        type="text"
        className={styles.search_input}
        placeholder={t("common_text.search")}
        value={valueSearch}
        onChange={(e) => setValueSearch(e.target.value)}
      />
    </div>
  );
};

// Prop types validation (optional in TypeScript)
SearchBar.propTypes = {
  valueSearch: PropTypes.string.isRequired,
  setValueSearch: PropTypes.func.isRequired,
  containerStyle: PropTypes.object,
};

export default SearchBar;
