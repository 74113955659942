import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";
import TableSticky from "components/TableSticky/TableSticky";
import { PictureData } from "types/games/puzzlesGameTypes";

function PuzzleSessionDetail() {
  const { t } = useTranslation();
  const { sessionDetailSelected } = usePatient();

  // Định nghĩa tiêu đề cho bảng
  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "pictureName", label: t("game.pictureName") },
    { key: "progress", label: t("game.progress") },
    { key: "spentTime", label: t("table.spentTime") },
  ];

  // Chuyển đổi dữ liệu sang định dạng bảng
  const convertDataToDataTable = (pictures: PictureData[]): any[] | null => {
    if (!pictures || pictures.length === 0) return null;

    return pictures.map((picture, index) => {
      return {
        order: index + 1,
        pictureName: picture.picName,
        progress: picture.completionRate,
        spentTime: picture.spentTime.toFixed(2),
      };
    });
  };

  return (
    <TableSticky
      headTabs={headTabs}
      data={convertDataToDataTable(sessionDetailSelected?.pictures)}
    />
  );
}

export default PuzzleSessionDetail;
