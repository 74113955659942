export const TMT_A = "TMT_A" as const;
export const TMT_B = "TMT_B" as const;
export const LINE_WORKER = "LINE_WORKER" as const;
export const TILT_THROW = "TILT_THROW" as const;
export const ADD_SUBTRACT = "ADD_SUBTRACT" as const;
export const HAND_SHAPER = "HAND_SHAPER" as const;
export const MEMORY_CARD = "MEMORY_CARD" as const;
export const JIGSAW_PUZZLE = "JIGSAW_PUZZLE" as const;

export type GameId =
  | typeof TMT_A
  | typeof TMT_B
  | typeof LINE_WORKER
  | typeof TILT_THROW
  | typeof ADD_SUBTRACT
  | typeof HAND_SHAPER
  | typeof MEMORY_CARD
  | typeof JIGSAW_PUZZLE;
