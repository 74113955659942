import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./BreadcrumbCus.module.scss";

function BreadcrumbCus() {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const pathname = location.pathname;
  const breadcrumbItems = useMemo(() => {
    const items = [
      {
        id: "home",
        title: "Home",
        path: "/dashboard",
      },
      {
        id: "createDashboard",
        title: "Create Dashboard",
        path: "/dashboard/create-dashboard",
      },
    ];

    const activeItems = items.filter((item) => pathname.startsWith(item.path));

    return activeItems.map((item) => ({
      title: (
        <Link
          to={item.path}
          style={{
            color: pathname === item.path ? "#1890ff" : "#959EAC",
          }}
        >
          {t(`header.${item.id}`)}
        </Link>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, i18n.language]);

  return (
    <Breadcrumb
      className={styles.breadcrumb_wrapper}
      separator=">"
      items={breadcrumbItems}
    />
  );
}

export default BreadcrumbCus;
