import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseData } from "types/apiTypes";
import { User } from "types/userTypes";
import axiosInstance from "utils/api";

interface MyselfState {
  me: User | null;
}

const initialState: MyselfState = {
  me: null,
};

export const myselfSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.me = action.payload;
    },
    clearUser: (state) => {
      state.me = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state) => {})
      .addCase(
        getMe.fulfilled,
        (state, action: PayloadAction<ResponseData<User>>) => {
          state.me = action.payload.data;
        }
      )
      .addCase(getMe.rejected, (state) => {})
      .addCase(updateMe.pending, (state) => {})
      .addCase(
        updateMe.fulfilled,
        (state, action: PayloadAction<ResponseData<Partial<User>>>) => {
          state.me = { ...state.me, ...action.payload.data };
        }
      )
      .addCase(updateMe.rejected, (state) => {});
  },
});

export const getMe = createAsyncThunk<ResponseData<User>, void>(
  "me/getMe",
  async () => {
    try {
      const response = await axiosInstance.get(`/users/me`);
      return response.data;
    } catch (error) {
      throw new Error("An unknown error occurred");
    }
  }
);

export const updateMe = createAsyncThunk<
  ResponseData<Partial<User>>,
  Partial<User>
>("me/updateMe", async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(`/users/me`, payload);
    return response.data;
  } catch (error) {
    return rejectWithValue("An unknown error occurred");
  }
});

export const { setUser, clearUser } = myselfSlice.actions;

export default myselfSlice.reducer;
