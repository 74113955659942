import { RootState } from "appdata/store";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import styles from "./LoaderContext.module.scss";

// Define the type for the LoaderContext
interface LoaderContextType {
  // Add any additional context values here if needed
}

// Create the LoaderContext with a default value of null
const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

// Custom hook to use the LoaderContext
export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (context === undefined) {
    throw new Error("useLoader must be used within a LoaderProvider");
  }
  return context;
};

// Define the props for the LoaderProvider
interface LoaderProviderProps {
  children: ReactNode;
}

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
  // Kiểu state.loaderRedux.isLoading trong store là boolean
  const isLoading = useSelector(
    (state: RootState) => state.loaderRedux.isLoading
  );

  const [animationData, setAnimationData] = useState<any>(null); // animationData có thể là kiểu JSON
  const [fadeOut, setFadeOut] = useState(false);
  const [wasLoading, setWasLoading] = useState(false);

  useEffect(() => {
    fetch(`/loading.json`)
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) =>
        console.error("Error loading Lottie animation:", error)
      );
  }, []);

  useEffect(() => {
    if (isLoading) {
      setFadeOut(false);
      setWasLoading(true);
    } else if (wasLoading) {
      const timeoutId = setTimeout(() => {
        setFadeOut(true);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [isLoading, wasLoading]);

  useEffect(() => {
    if (!isLoading && fadeOut) {
      const timeoutId = setTimeout(() => {
        setWasLoading(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [fadeOut, isLoading]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LoaderContext.Provider value={{}}>
      {children}
      {(isLoading || wasLoading) && (
        <div className={`${styles.overlay} ${fadeOut ? styles.fadeOut : ""}`}>
          <div className={`${styles.loader} ${fadeOut ? styles.fadeOut : ""}`}>
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        </div>
      )}
    </LoaderContext.Provider>
  );
};
