import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import styles from "./Loader.module.scss";

interface LoaderProps {
  isLoading: boolean;
  setLoading?: (loading: boolean) => void;
}

const Loader: React.FC<LoaderProps> = ({ isLoading, setLoading }) => {
  const [animationData, setAnimationData] = useState(null);
  const [fadeOut, setFadeOut] = useState(false);
  const [wasLoading, setWasLoading] = useState(false);

  // Fetch Lottie animation JSON file on component mount
  useEffect(() => {
    fetch(`/loading.json`)
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) =>
        console.error("Error loading Lottie animation:", error)
      );
  }, []);

  // Handle fade-out transition logic
  useEffect(() => {
    if (isLoading) {
      setFadeOut(false);
      setWasLoading(true);
    } else if (wasLoading) {
      const timeoutId = setTimeout(() => {
        setFadeOut(true);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [isLoading, wasLoading]);

  // Reset wasLoading state after fade-out completes
  useEffect(() => {
    if (!isLoading && fadeOut) {
      const timeoutId = setTimeout(() => {
        setWasLoading(false);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [fadeOut, isLoading]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Trả về `null` nếu không có gì cần render
  if (!isLoading && !wasLoading) {
    return null;
  }

  return (
    <div className={`${styles.overlay} ${fadeOut ? styles.fadeOut : ""}`}>
      <div className={`${styles.loader} ${fadeOut ? styles.fadeOut : ""}`}>
        {animationData && (
          <Lottie options={defaultOptions} height={200} width={200} />
        )}
      </div>
    </div>
  );
};
export default Loader;
