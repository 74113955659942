import { ToastOptions } from "react-toastify";

export const toastConfig: ToastOptions = {
  position: "top-right", // This should match the ToastPosition union
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};
