import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import NoDataImg from "assets/img/no-data.webp";

import styles from "./TableCus.module.scss";
import { StyledItem } from "components/TableSticky/TableSticky";

interface TableCusProps {
  headTabs: { key: string; label: string }[]; // Array of header tabs with key and label
  data: Record<string, any>[]; // Array of objects representing rows
  headerStyles?: StyledItem[]; // Optional array of styles for headers
  columnStyles?: StyledItem[]; // Optional array of styles for columns
}

const TableCus: React.FC<TableCusProps> = ({
  headTabs,
  data,
  headerStyles = [],
  columnStyles = [],
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ overflowX: "auto", width: "100%" }} className={styles.test}>
      <Table borderless hover responsive className={styles.table_wrapper}>
        <thead className={styles.table_head}>
          <tr>
            {headTabs.map((tab) => {
              const style =
                headerStyles.find((style) => style.key === tab.key)?.style ||
                {};
              return (
                <th key={tab.key} align="center" style={style}>
                  {tab.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.table_body}>
          {data?.map((item, index) => (
            <tr key={index}>
              {headTabs.map((tab) => {
                const style =
                  columnStyles.find((style) => style.key === tab.key)?.style ||
                  {};
                return (
                  <td key={tab.key} align="center" style={style}>
                    {item[tab.key]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>

      {(!data || data.length === 0) && (
        <div className={styles.table_nodata}>
          <div>
            <img src={NoDataImg} alt="nodata" />
            <div>{t("table.noData")}</div>
          </div>
        </div>
      )}
    </div>
  );
};

// Prop types validation (optional in TypeScript)
TableCus.propTypes = {
  headTabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  data: PropTypes.array.isRequired,
  headerStyles: PropTypes.array,
  columnStyles: PropTypes.array,
};

export default TableCus;
