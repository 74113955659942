import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import styles from "./Popup.module.scss";

interface PopupContextProps {
  isSwitchUserPopupOpen: boolean;
  setIsSwitchUserPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupContext = createContext<PopupContextProps | undefined>(undefined);

export const PopupProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();

  const [isSwitchUserPopupOpen, setIsSwitchUserPopupOpen] =
    useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      isSwitchUserPopupOpen,
      setIsSwitchUserPopupOpen,
    }),
    [isSwitchUserPopupOpen]
  );

  return (
    <PopupContext.Provider value={contextValue}>
      {children}

      <Popup
        contentStyle={{ zIndex: 1001 }}
        open={isSwitchUserPopupOpen}
        modal
        onClose={() => setIsSwitchUserPopupOpen(false)}
        closeOnDocumentClick
      >
        <div
          style={{ maxWidth: "60vw", minWidth: "40vw" }}
          className={styles.customPopup}
        >
          <button
            className={`${styles.close_btn} size_1`}
            onClick={() => setIsSwitchUserPopupOpen(false)}
          >
            &times;
          </button>
          <div className={`size_1 ${styles.title}`}>
            {t("common_text.userSwitching")}
          </div>

          <div className={styles.popup_content}>
            <p>{t("common_text.enterTheCode")}</p>
            <input placeholder={t("common_text.enterThePatientCode")} />

            <button className={styles.switching_btn}>
              {t("common_text.switching")}
            </button>
          </div>
        </div>
      </Popup>
    </PopupContext.Provider>
  );
};

PopupProvider.propTypes = {
  children: PropTypes.node.isRequired, // Đảm bảo rằng prop children là hợp lệ
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("usePopup must be used within a PopupProvider");
  }
  return context;
};
