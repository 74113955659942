import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BaseApiParams,
  PageResponseData,
  PaginationMetadata,
  ResponseData,
} from "types/apiTypes";
import { LineChartData } from "types/charts/lineChartTypes";
import { User } from "types/userTypes";
import axiosInstance from "utils/api";
import { generateQueryParam } from "utils/urlEncode";

interface InitialState {
  patients: User[] | null;
  metadataPatientsPage: PaginationMetadata | null;
  useInfinityScroll: boolean;
  patientsOfDoctor: User[] | null;
  metadataPatientsOfDoctorPage: PaginationMetadata | null;
  resultGameOfPatient: any;
  analyticsGameOfPatient: LineChartData | null;
}

const initialState: InitialState = {
  patients: null,
  metadataPatientsPage: null,
  useInfinityScroll: false,
  patientsOfDoctor: null,
  metadataPatientsOfDoctorPage: null,
  resultGameOfPatient: null,
  analyticsGameOfPatient: null,
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setPatientsData: (state, action) => {
      state.patients = action.payload;
    },
    setPatientsOfDoctorData: (state, action) => {
      state.patientsOfDoctor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatients.pending, (state) => {})
      .addCase(
        getPatients.fulfilled,
        (
          state,
          action: PayloadAction<
            PageResponseData<User> & { useInfinityScroll: boolean }
          >
        ) => {
          const { data, meta, useInfinityScroll } = action.payload;

          if (useInfinityScroll) {
            if (state.patients === null) {
              state.patients = data;
            } else {
              state.patients = [...state.patients, ...data];
            }
            state.useInfinityScroll = true;
          } else {
            state.patients = data;
          }
          state.metadataPatientsPage = meta;
        }
      )
      .addCase(getPatients.rejected, (state) => {})
      .addCase(getPatientsOfDoctor.pending, (state) => {})
      .addCase(
        getPatientsOfDoctor.fulfilled,
        (state, action: PayloadAction<PageResponseData<User>>) => {
          state.patientsOfDoctor = action.payload.data;
          state.metadataPatientsOfDoctorPage = action.payload.meta;
        }
      )
      .addCase(getPatientsOfDoctor.rejected, (state) => {})
      .addCase(getAnalyticsGameOfPatient.pending, (state) => {})
      .addCase(
        getAnalyticsGameOfPatient.fulfilled,
        (state, action: PayloadAction<ResponseData<LineChartData>>) => {
          state.analyticsGameOfPatient = action.payload.data;
        }
      )
      .addCase(getAnalyticsGameOfPatient.rejected, (state) => {});
  },
});

export interface GetPatientsParams extends BaseApiParams {
  name?: string;
  useInfinityScroll?: boolean;
}

export const getPatients = createAsyncThunk<
  PageResponseData<User> & { useInfinityScroll: boolean },
  GetPatientsParams | undefined
>("patients/getPatients", async (params) => {
  const { useInfinityScroll = false, ...otherParams } = params || {};
  try {
    const queryStringPart = generateQueryParam(otherParams);
    const response = await axiosInstance.get(`/patients/all${queryStringPart}`);

    const { data, success, message, meta } = response.data;

    return {
      data,
      success,
      message,
      meta,
      useInfinityScroll,
    };
  } catch (error) {
    throw new Error("An unknown error occurred");
  }
});

export interface GetPatientsOfDoctorParams extends BaseApiParams {
  name?: string;
}

export const getPatientsOfDoctor = createAsyncThunk<
  PageResponseData<User>,
  GetPatientsOfDoctorParams | undefined
>("patients/getPatientsOfDoctor", async (params) => {
  try {
    const queryStringPart = generateQueryParam(params || {});
    const response = await axiosInstance.get(`/patients${queryStringPart}`);
    return response.data;
  } catch (error) {
    throw new Error("An unknown error occurred");
  }
});

export const updatePatientsOfDoctor = createAsyncThunk(
  "patients/updatePatientsOfDoctor",
  async (body: { patients: string[] }) => {
    try {
      const response = await axiosInstance.put(`/patients`, body);
      return response.data; // Cập nhật dữ liệu cho bệnh nhân
    } catch (error) {
      throw new Error("An unknown error occurred");
    }
  }
);

export interface GetResultGameParams {
  patientId: string;
  gameId: string;
  start?: string;
  end?: string;
}

export const getPdfOfPatient = createAsyncThunk<
  { blob: Blob }, // Chỉ trả về Blob
  GetResultGameParams
>("patients/getPdfOfPatient", async (params) => {
  const { patientId, gameId, ...otherParams } = params;
  const queryStringPart = generateQueryParam(otherParams || {});

  // Gọi API và lấy Blob trực tiếp
  const response = await axiosInstance.get(
    `/patients/${patientId}/games/${gameId}/pdf${queryStringPart}`,
    { responseType: "blob" } // Đặt responseType thành 'blob' ở đây
  );

  if (response.status === 200) {
    return { blob: response.data }; // Trả về Blob
  } else {
    throw new Error("Failed to generate PDF");
  }
});

export const getResultGameOfPatient = createAsyncThunk<
  ResponseData<any>,
  GetResultGameParams
>("patients/getResultGameOfPatient", async (params) => {
  const { patientId, gameId, ...otherParams } = params;
  const queryStringPart = generateQueryParam(otherParams || {});

  const response = await axiosInstance.get(
    `/patients/${patientId}/games/${gameId}/result${queryStringPart}`
  );
  return response.data;
});

export const getAnalyticsGameOfPatient = createAsyncThunk<
  ResponseData<LineChartData>,
  GetResultGameParams
>("patients/getResultGameOfPatient", async (params) => {
  const { patientId, gameId, ...otherParams } = params;
  const queryStringPart = generateQueryParam(otherParams || {});

  const response = await axiosInstance.get(
    `/patients/${patientId}/games/${gameId}/graph-analytics${queryStringPart}`
  );
  return response.data;
});

export const { setPatientsData, setPatientsOfDoctorData } =
  patientsSlice.actions;

export default patientsSlice.reducer;
