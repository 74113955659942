export type LanguageCode = "vi" | "ja" | "en";

export const LANGUAGE_CODES: Record<LanguageCode, string> = {
  vi: "vi",
  ja: "ja",
  en: "en",
};

export const LANGUAGE_NAMES: Record<LanguageCode, string> = {
  vi: "Việt Nam",
  ja: "日本語",
  en: "English",
};
