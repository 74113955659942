import React from "react";
import PropTypes from "prop-types";
import styles from "./TableSticky.module.scss";

// Định nghĩa kiểu cho các props
interface HeadTab {
  key: string;
  label: string;
}

export interface StyledItem {
  key: string; // Trường key
  style?: React.CSSProperties; // Sử dụng React.CSSProperties để định nghĩa các thuộc tính CSS tùy ý
}

interface TableStickyProps {
  headTabs: HeadTab[];
  data: Array<Record<string, any>> | null;
  headerStyles?: StyledItem[]; // Sử dụng kiểu StyledItem cho headerStyles
  columnStyles?: StyledItem[]; // Sử dụng kiểu StyledItem cho columnStyles
}

function TableSticky({
  headTabs,
  data,
  headerStyles = [],
  columnStyles = [],
}: TableStickyProps) {
  return (
    <div className={styles.table_scroll}>
      <table>
        <thead>
          <tr>
            {headTabs.map((tab) => {
              const headerStyle =
                headerStyles.find((style) => style.key === tab.key)?.style ||
                {};
              return (
                <th key={tab.key} align="center" style={headerStyle}>
                  {tab.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              {headTabs.map((tab) => {
                const columnStyle =
                  columnStyles.find((style) => style.key === tab.key)?.style ||
                  {};
                return (
                  <td key={tab.key} style={columnStyle}>
                    {item[tab.key]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Kiểm tra kiểu dữ liệu cho props
TableSticky.propTypes = {
  headTabs: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  headerStyles: PropTypes.array,
  columnStyles: PropTypes.array,
};

export default TableSticky;
