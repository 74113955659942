import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import {
  LineWorkerDataTableRow,
  LineWorkerSessionData,
} from "types/games/lineWorkerGameTypes";
import GameDetailLayout from "../GameDetailLayout";

function LineWorkerDetail() {
  const { t } = useTranslation();
  const { results } = usePatient();

  const headTabs = [
    { key: "order", label: t("game.practiceTimes") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "sideBends", label: t("game.sideBends") },
  ];

  const convertDataToDataTable = (
    _data: LineWorkerSessionData[]
  ): LineWorkerDataTableRow[] | null => {
    if (_data.length === 0) return null;

    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        sideBends: session.lookDatas.length,
      };
    });
  };

  const totalSpentTime = results.totalTime.toFixed(0);

  return (
    <GameDetailLayout
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      data={results.result}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default LineWorkerDetail;
