import { setLoading } from "appdata/loader/loaderSlice";
import { setUser, updateMe } from "appdata/myself/myselfSlice";
import Popover from "components/Popover/Popover";
import { LANGUAGE_CODES, LANGUAGE_NAMES, LanguageCode } from "helps/Languages";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ArrowDownIcon from "assets/icons/arrow-down.svg";
import CheckIcon from "assets/icons/check.svg";
import LanguageIcon from "assets/icons/language.svg";
import AnhLoginImg from "assets/img/anh-login.webp";
import MiraboImg from "assets/img/mirabo-logo.webp";

import styles from "./Login.module.scss";
import { AppDispatch } from "appdata/store";

function Login() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [customId, setCustomId] = useState("");
  const [isDropdownLanguageOpen, setIsDropdownLanguageOpen] = useState(false);
  const [anchorElRegion, setAnchorElRegion] = useState<HTMLElement | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem("mi01_la", value);
    handleCloseRegion(); // Đóng dropdown sau khi chọn ngôn ngữ
  };

  const handleRegionClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElRegion(e.currentTarget);
    setIsDropdownLanguageOpen(true);
  };

  const handleCloseRegion = () => {
    setAnchorElRegion(null);
    setIsDropdownLanguageOpen(false);
  };

  const handleLogin = async () => {
    if (customId === "") return;
    dispatch(setLoading(true));
    setErrorMessage(""); // Reset error message before login

    const loginData = {
      username: customId,
      type: "doctor",
    };

    try {
      const response = await fetch(
        `${window._env_.REACT_APP_API_URL}/api/v1/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const result = await response.json();
      const language = localStorage.getItem("mi01_la") ?? "ja";
      dispatch(
        updateMe({
          language: language === "vi" ? "vi-VN" : language,
        })
      );
      dispatch(setUser(result.user));

      const { accessToken, refreshToken } = result;

      const authData = {
        accessToken,
        refreshToken,
      };

      localStorage.setItem("mi01_auth", JSON.stringify(authData));
      navigate("/dashboard");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message); // Hiển thị thông báo lỗi cho người dùng
      } else {
        setErrorMessage("An unknown error occurred");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const openRegion = Boolean(anchorElRegion);
  const idRegion = openRegion ? "region-popover" : undefined;

  return (
    <div className={styles.login_container}>
      <div className={styles.login_left}>
        <img src={AnhLoginImg} alt="Anh login" />
      </div>

      <div className={styles.login_right}>
        <>
          <button
            className={`${styles.language_btn} rounded`}
            onClick={handleRegionClick}
          >
            <img
              style={{
                objectFit: "cover",
              }}
              src={LanguageIcon}
              alt="Globe icon"
            />
            <span className={`size_small`} style={{ textWrap: "nowrap" }}>
              {
                LANGUAGE_NAMES[
                  (localStorage.getItem("mi01_la") as LanguageCode) || "ja"
                ]
              }
            </span>
            <img src={ArrowDownIcon} alt="Dropdown arrow" />
          </button>

          <Popover
            id={idRegion}
            open={openRegion}
            anchorEl={anchorElRegion}
            onClose={handleCloseRegion}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div
              className={`${styles.language_options} ${
                isDropdownLanguageOpen ? styles.open : styles.closed
              }`}
            >
              {Object.entries(LANGUAGE_CODES).map(([key, value]) => (
                <button
                  key={key}
                  className={`${styles.language_option} ${
                    i18n.language === value ? styles.selected : ""
                  }`}
                  onClick={() => onChangeLanguage(value)}
                >
                  <span>{LANGUAGE_NAMES[value as LanguageCode]}</span>
                  {i18n.language === value && (
                    <img src={CheckIcon} alt="Check icon" />
                  )}
                </button>
              ))}
            </div>
          </Popover>
        </>
        <div className={styles.login_form}>
          <img src={MiraboImg} alt="Product Logo" className={styles.logo} />
          <h1 className={`size_large`}>{t("login_page.loginTitle")}</h1>

          {errorMessage && (
            <div className={styles.error_message}>{errorMessage}</div>
          )}

          <label htmlFor="code-input" className={`${styles.label} size_medium`}>
            {t("common_text.enterTheCode")}
          </label>
          <input
            type="text"
            id="code-input"
            className={`${styles.input} size_medium`}
            placeholder={t("common_text.enterTheDoctorCode")}
            value={customId}
            onChange={(e) => setCustomId(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleLogin()}
            autoComplete="off"
          />
          <button
            className={`${styles.login_button} button_primary btn-large rounded`}
            onClick={handleLogin}
          >
            {t("login_page.login")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
