import { ReactNode, useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";

import styles from "./Popover.module.scss";

type AnchorElement = HTMLElement | null;

interface PopoverProps {
  id?: string;
  open: boolean;
  anchorEl: AnchorElement; // Adjusting type to include null
  onClose: () => void;
  anchorOrigin?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "right";
  };
  transformOrigin?: {
    vertical: "top" | "bottom" | "center";
    horizontal: "left" | "right" | "center";
  };
  children: ReactNode;
}

const Popover: React.FC<PopoverProps> = ({
  id,
  open,
  anchorEl,
  onClose,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  children,
}) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node) &&
      anchorEl &&
      !anchorEl.contains(event.target as Node)
    ) {
      onClose();
    }
  };

  useLayoutEffect(() => {
    if (!anchorEl) return;

    const { vertical: anchorVertical, horizontal: anchorHorizontal } =
      anchorOrigin;
    const { vertical: transformVertical, horizontal: transformHorizontal } =
      transformOrigin;

    const calculateTop = (): string => {
      if (!popoverRef.current) return "0px"; // Trả về '0px' nếu popoverRef không tồn tại
      const popoverHeight = popoverRef.current.offsetHeight;
      const anchorTop = anchorEl.offsetTop;
      const anchorBottom = anchorEl.offsetTop + anchorEl.offsetHeight;
      const isAnchorVerticalTop = anchorVertical === "top";
      const isTransformVerticalTop = transformVertical === "top";
      const isTransformVerticalBottom = transformVertical === "bottom";

      let top: number; // Thay đổi kiểu của top thành number để thực hiện phép toán
      if (isAnchorVerticalTop) {
        top = anchorTop - (isTransformVerticalTop ? 0 : popoverHeight);
      } else {
        top =
          anchorBottom - (isTransformVerticalBottom ? popoverHeight : 0) + 15;
      }
      return `${top}px`; // Trả về giá trị dưới dạng chuỗi
    };

    const calculateLeft = (): string => {
      if (!popoverRef.current) return "0px"; // Trả về '0px' nếu popoverRef không tồn tại

      const popoverWidth = popoverRef.current.offsetWidth;
      const anchorLeft = anchorEl.offsetLeft;
      const anchorRight = anchorEl.offsetLeft + anchorEl.offsetWidth;
      const isAnchorHorizontalLeft = anchorHorizontal === "left";
      const isTransformHorizontalLeft = transformHorizontal === "left";
      const isTransformHorizontalRight = transformHorizontal === "right";

      let left: number; // Thay đổi kiểu của left thành number để thực hiện phép toán
      if (isAnchorHorizontalLeft) {
        left = anchorLeft - (isTransformHorizontalLeft ? 0 : popoverWidth);
      } else {
        left = anchorRight - (isTransformHorizontalRight ? popoverWidth : 0);
      }

      return `${left}px`; // Trả về giá trị dưới dạng chuỗi
    };

    const top = calculateTop();
    const left = calculateLeft();

    if (popoverRef.current) {
      popoverRef.current.style.position = "absolute";
      popoverRef.current.style.top = top;
      popoverRef.current.style.left = left;
      popoverRef.current.style.transformOrigin = `${transformHorizontal} ${transformVertical}`;
    }
  }, [anchorEl, anchorOrigin, transformOrigin]);

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, anchorEl]);

  if (!open || !anchorEl) {
    return null;
  }

  return (
    <div ref={popoverRef} id={id} className={styles.popover_content}>
      {children}
    </div>
  );
};

Popover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.instanceOf(HTMLElement), // Chỉ cần khai báo HTMLElement
    PropTypes.oneOf([null]), // Đảm bảo cho phép null
  ]), // Đánh dấu là bắt buộc
  onClose: PropTypes.func.isRequired,

  children: PropTypes.node.isRequired,
};

export default Popover;
